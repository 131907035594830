import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";

class ButtonWithToolTip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toolTipOpen: false
        }
    }
    render() {
        return <span>
          <Button {...this.props.buttonProps} id={'Tooltip-' + this.props.id}>
            {this.props.buttonText}
          </Button>
          <Tooltip
              placement={this.props.placement ? this.props.placement : "top"}
              isOpen={this.state.toolTipOpen}
              target={'Tooltip-' + this.props.id}
              toggle={() => this.setState({toolTipOpen: !this.state.toolTipOpen})}
          >
           {this.props.toolTipText}
          </Tooltip>
        </span>
    }
}

ButtonWithToolTip.propTypes = {
    id: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonProps: PropTypes.object.isRequired,
    placement: PropTypes.string,
    toolTipText: PropTypes.string
};


export default ButtonWithToolTip;