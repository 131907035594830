import ErrorBoundaryWrapper from "../molecules/ErrorBoundary.js";
import ErrorNotification from "../atoms/ErrorNotification.js";
import ErrorButton from "../atoms/ErrorButton.js";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {WelcomePage} from "../pages/WelcomePage.js";
import SaturnStoresPage from "../pages/SaturnStoresPage.js";
import AccountsPage from "../pages/AccountsPage.js";
import AccountPage from "../pages/AccountPage.js";
import QuotePage from "../pages/QuotePage.js";
import QuotesPage from "../pages/QuotesPage.js";
import ChangePasswordPage from "../pages/ChangePasswordPage.js";
import UsersPage from "../pages/UsersPage.js";
import React from "react";
import PropTypes from "prop-types";

const AppRoutes = ({ showErrorButton, quoteToolClient, user}) => {
    console.log("AppRoutes");

    return (<BrowserRouter>
        <ErrorBoundaryWrapper>
            <ErrorNotification />
            {showErrorButton && <ErrorButton />}
            <Routes>
                <Route
                    path="/"
                    element={
                        <WelcomePage
                            exampleBool={true}
                        />}
                />
                <Route
                    path="/stores"
                    element={
                        <SaturnStoresPage
                            quoteToolClient={quoteToolClient}
                        />}
                />
                <Route
                    path="/accounts"
                    element={
                        <AccountsPage
                            quoteToolClient={quoteToolClient}
                        />}
                />
                <Route
                    path="/accounts/:accountId"
                    element={
                        <AccountPage
                            quoteToolClient={quoteToolClient}/>}
                />
                <Route
                    path="/stores/:storeId/quotes/:quoteRef?"
                    element={
                        <QuotePage
                            quoteToolClient={quoteToolClient}
                        />}
                />
                <Route
                    path="/quotations"
                    element={
                        <QuotesPage
                            quoteToolClient={quoteToolClient}
                        />}
                />
                <Route
                    path="/users/change-password"
                    element={
                        <ChangePasswordPage
                            quoteToolClient={quoteToolClient}
                        />}
                />
                {user.roles.includes("admin") && <Route
                    path="/admin/users"
                    element={
                        <UsersPage
                            quoteToolClient={quoteToolClient}
                            currentUserId={user.sub}
                        />}
                />}
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        </ErrorBoundaryWrapper>
    </BrowserRouter>)
};

AppRoutes.propTypes = {
    showErrorButton: PropTypes.bool,
    quoteToolClient: PropTypes.object.isRequired,
    user: PropTypes.object
}

export default AppRoutes;