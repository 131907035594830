import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import LoginPage from "../pages/LoginPage.js";
import CreateAccountPage from "../pages/CreateAccountPage.js";
import ForgotPasswordForm from "../atoms/ForgotPasswordForm.js";
import {ToastContainer} from "react-toastify";

const AuthRoutes = ({ }) => {
    console.log("AUTH ROUTES");
    return (<BrowserRouter>
        <Routes>

            <Route
                path="/auth/login"
                element={<LoginPage/>}
            />
            <Route
                path="/auth/create-account"
                element={<CreateAccountPage />}
            />
            <Route
                path="/auth/forgot-password"
                element={<ForgotPasswordForm  />}
            />
            <Route
                path="*"
                element={<Navigate to="/auth/login" />}
            />
        </Routes>
        <ToastContainer />
    </BrowserRouter>)
};

export default AuthRoutes;