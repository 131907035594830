import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import QuoteToolNavBar from "../molecules/QuoteToolNavBar";
import withRouter from "../../helper/withRouter";
import Cookies from 'universal-cookie';
import { storeIdMap } from '@kjdelectronics/ps-quotetool-domain/domain/saturn/SaturnStoreIdMap';
import QuoteToolClient from "@kjdelectronics/ps-quotetool-domain/service/QuoteToolClient";
import Loading from "../molecules/Loading.js";
import LoadingSpinner from "../atoms/LoadingSpinner";

const cookies = new Cookies();

class SaturnStoresPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: [],
            error: null,
            loading: true,
        };
    }

    async componentDidMount() {
        const { quoteToolClient } = this.props;
        try {
            const stores = await quoteToolClient.getSaturnStores();
            console.log('Fetched stores:', stores);
            this.setState({ stores, loading: false });
        } catch (error) {
            this.setState({ error: 'Failed to fetch stores', loading: false });
        }
    }


    render() {
        const { stores, loading } = this.state;
        if (loading) {
            return <div>
                <QuoteToolNavBar/>
                <LoadingSpinner/>;
            </div>
        }
        return (
            <div className="ml-3 mr-3 ">
                <QuoteToolNavBar/>
                <div className="d-flex justify-content-center">
                    <Table style={{ maxWidth: "750px" }}>
                        <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Store</th>
                            <th>Quote Tool</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stores.map(store => (
                            <tr key={store.id}>
                                <td><img height="50px" src={`https://web.warehouseparadise.com/${store.logo}`} alt={store.name}/></td>
                                <td>{store.name}</td>
                                <td>
                                    <Link to={`/stores/${store.id}/quotes/`}>
                                        <Button color="secondary">
                                            New {store.shortName} Quote
                                        </Button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

SaturnStoresPage.propTypes = {
    quoteToolClient: PropTypes.object.isRequired
};

export default withRouter(SaturnStoresPage);