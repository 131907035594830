import React from 'react';
import "../../css/CustomModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CustomModal = ({ isOpen, toggle, children }) => {
    if (!isOpen) return null;

    return (
        <div className="custom-modal-overlay" onClick={toggle}>
            <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={toggle}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {children}
            </div>
        </div>
    );
};

export default CustomModal;