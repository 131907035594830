import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import TaxableSwitch from './TaxableSwitch';

const AccountPageHeading = ({ name, account, onAccountNameChange, onTaxableChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const toggleEditMode = () => setIsEditing(!isEditing);
    const handleNameBlur = () => {
        onAccountNameChange(currentName);
        setIsEditing(false);
    };

    return (
        <Row className="align-items-center mt-3">
            <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Account -</h2>
                    {isEditing ? (
                        <Input
                            type="text"
                            value={currentName}
                            onChange={(e) => setCurrentName(e.target.value)}
                            onBlur={handleNameBlur}
                            autoFocus
                            style={{ marginLeft: '10px', fontSize: '1.5rem', width: 'auto' }}
                        />
                    ) : (
                        <div
                            onClick={() => !name && toggleEditMode()}
                            style={{
                                marginLeft: '10px',
                                cursor: name ? 'default' : 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <h2 style={{ margin: 0 }}>
                                {name || <i style={{ color: 'gray' }}>Add an Account Name</i>}
                            </h2>
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={toggleEditMode}
                                style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '1.5rem' }}
                            />
                        </div>
                    )}
                </div>
            </Col>
            <Col className="text-end" lg={4}>
                <TaxableSwitch
                    isTaxable={account.isTaxable}
                    onChange={onTaxableChange}
                    className="me-3"
                    text=" "
                />
            </Col>
            <Col className="text-end" lg={2}>
                <Link
                    to={
                        account.defaults.storeId != null
                            ? `/stores/${account.defaults.storeId}/quotes?accountId=${account.id}`
                            : ""
                    }
                >
                    <Button
                        disabled={account.defaults.storeId == null}
                        color="success"
                        outline
                        size="sm"
                        style={{ marginLeft: '15px' }}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ color: 'green', marginRight: '5px' }} /> New Quote
                    </Button>
                </Link>
            </Col>
        </Row>
    );
};

export default AccountPageHeading;
