import React from 'react';
import PropTypes from 'prop-types';
import AppContext from "../../context/AppContext";
import { Alert, Badge, Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import QuoteToolNavBar from "../molecules/QuoteToolNavBar";
import { Link } from 'react-router-dom';

class WelcomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            text: "Test"
        }
    }

    renderCount() {
        if (this.state.count === 0)
            return ""; //Don't show anything
        return <h3>
            <Badge>
                You have clicked the button {this.state.count} time{this.state.count !== 1 ? 's' : ''}
            </Badge>
        </h3>
    }

    clickMeOnClick(){
        //Manipulating  react state is typically simple but it is important to remember it can
        //sometimes have strange behavior. This is one of those cases where you want to make sure
        //the state does not get fouled up because React processes something out of order.
        return this.setState(previousState => ({
            count: previousState.count + 1
        }));
    }

    clearCount(){
        return this.setState({
            count: 0 //setState can also be called directly
        })
    }

    customLogicOnChange({value}){
        this.setState({text: value}, (state) => {
            if(value === "Test 123")
                alert("Test 123");
        });
        console.log(this.state.text);
    }

    render() {
        return (
            <AppContext.Consumer>
                {({user}) => (
                    <div className="ml-3 mr-3">
                        <QuoteToolNavBar />
                        <h3>Welcome, {user.firstName} to PiShop Quote Tool</h3>
                        {this.props.exampleBool && <Alert>
                            Example Bool Must be True!
                        </Alert>}
                        <hr/>
                        <Button className="mt-3" color="primary" onClick={this.clickMeOnClick.bind(this)}>Click Me</Button>
                        <Button className="mt-3 ms-2" color="danger" onClick={this.clearCount.bind(this)}>Clear Count</Button>
                        {this.renderCount()}
                        <hr/>
                        <Link to="/stores">
                            <Button className="mt-3" color="info">View All Stores</Button>
                        </Link>
                        <Link to="/stores/1000104959/quotes">
                            <Button className="mt-3 ms-2" color="success">New US Quote</Button>
                        </Link>
                        <Link to="/stores/1000099708/quotes">
                            <Button className="mt-3 ms-2" color="warning">New CA Quote</Button>
                        </Link>
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

WelcomePage.propTypes = {
    exampleBool: PropTypes.bool
};

export {WelcomePage};