import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CustomModal from './CustomModal';
import { v4 as uuidv4 } from 'uuid';


const SortableItem = ({ item }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.tempId,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '8px',
        margin: '0 0 8px 0',
        border: '1px solid lightgrey',
        borderRadius: '4px',
        backgroundColor: 'white',
        cursor: 'grab',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {item.sku || 'Unknown SKU'} - {item.title || 'No Title'} - {item.quantity}x
        </div>
    );
};

const ReorderModal = ({ isOpen, toggle, lineItems, saveOrder }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(
            lineItems.map((item) => ({
                ...item,
                tempId:
                    item.id !== null && item.id !== undefined
                        ? item.id.toString()
                        : uuidv4(),
            }))
        );
    }, [lineItems]);

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((prevItems) => {
                const oldIndex = prevItems.findIndex((item) => item.tempId === active.id);
                const newIndex = prevItems.findIndex((item) => item.tempId === over.id);
                return arrayMove(prevItems, oldIndex, newIndex);
            });
        }
    };

    return (
        <CustomModal isOpen={isOpen} toggle={toggle}>
            <h4>Reorder Line Items</h4>
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={items.map((item) => item.tempId)} strategy={verticalListSortingStrategy}>
                    {items.map((item) => (
                        <SortableItem key={item.tempId} item={item} />
                    ))}
                </SortableContext>
            </DndContext>
            <div style={{marginTop: '20px', textAlign: 'right'}}>
                <Button
                    color="primary"
                    onClick={() => {
                        saveOrder(items);
                        toggle();
                    }}
                    style={{marginRight: '10px'}}
                >
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </div>
        </CustomModal>
    );
};

export default ReorderModal;
