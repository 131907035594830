import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Modal, ModalHeader, ModalBody, Button, Row, Col, Container
} from 'reactstrap';
import AddressSelectionModal from "./AddressSelectionModal.js";
import QuoteDetailAddress from "./QuoteDetailAddress.js";

const QuoteDetailAddresses = ({quote, patchQuote, quoteToolClient}) => {
    const [showAddressSelectionModal, setShowAddressSelectionModal] = useState(false);
    const [addressSelectType, setAddressSelectType] = useState("");

    const handleSelectExitingButtonClick = (addressType) => {
        setShowAddressSelectionModal(true);
        setAddressSelectType(addressType);
    }

    const handleAddressUpdate = ({addressType, newAddress}) => {
        console.log(addressType, newAddress);

        patchQuote({
            keyArray: ["addresses", addressType],
            newValue: newAddress
        })
    }

    const handleModalCLose = () => {
        setShowAddressSelectionModal(false)
    }

    const handleAccountUpdated = account => {
        patchQuote({
            keyArray: ['account'],
            newValue: account
        });
    }

    const handleAddressSelected = selectedAddress => {
        console.log(addressSelectType, selectedAddress);
        patchQuote({
            keyArray: ["addresses", addressSelectType],
            newValue: selectedAddress
        })
    }

    return (
        <Container className="address-container">
            <Row className="mt-3 justify-content-start">
                <QuoteDetailAddress addressType="shippingAddress"
                                    handleSelectExitingButtonClick={handleSelectExitingButtonClick}
                                    handleAddressUpdate={handleAddressUpdate}
                                    header="Shipping"
                                    quoteToolClient={quoteToolClient}
                                    quote={quote}
                                    patchQuote={patchQuote}/>
                <Col xs={12} md={1}></Col> {/* Spacer column */}
                <QuoteDetailAddress addressType="billingAddress"
                                    handleSelectExitingButtonClick={handleSelectExitingButtonClick}
                                    handleAddressUpdate={handleAddressUpdate}
                                    header="Billing"
                                    quoteToolClient={quoteToolClient}
                                    quote={quote}
                                    patchQuote={patchQuote}/>

            {/* AddressSelectionModal should be conditionally rendered based on state */}
            {showAddressSelectionModal && (
                <AddressSelectionModal
                    account={quote.account}
                    onClose={() => handleModalCLose()}
                    onAddressSelected={handleAddressSelected}
                    isOpen={showAddressSelectionModal}
                    quoteToolClient={quoteToolClient}
                    onAccountUpdated={handleAccountUpdated}/>
            )}
            </Row>
        </Container>
    );
};

QuoteDetailAddresses.propTypes = {
    quote: PropTypes.object.isRequired,
    patchQuote: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
};

export default QuoteDetailAddresses;
