import React from "react";
import QuoteToolNavBar from "../molecules/QuoteToolNavBar";
import withRouter from "../../helper/withRouter.js";
import QuoteTable from "../molecules/QuoteTable.js";

class QuotesPage extends React.Component {
    render() {
        return (
            <>
                <QuoteToolNavBar />
                <h1>Quote Table</h1>
                <QuoteTable quoteToolClient={this.props.quoteToolClient}/>
            </>
        );
    }
}

export default withRouter(QuotesPage);
