import React from 'react';
import PropTypes from 'prop-types';
import QuoteLineItemRow from './QuoteLineItemRow';
import {Button, Col, Input, InputGroup, InputGroupText, Row, Tooltip, Progress } from "reactstrap";
import {usdFormatter} from "../../helper/formatters.js";
import NumericInput from "../atoms/NumericInput";
import ReorderModal from "../atoms/ReorderModal";
import { FALLBACK_CURRENCY_RATES } from "@kjdelectronics/ps-quotetool-domain/obj/quote/CurrencyRates"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import QuoteTotals from "./QuoteTotals.js";
import patchQuote from "@kjdelectronics/ps-quotetool-domain/domain/PatchQuote.js";
import LoadingSpinner from "../atoms/LoadingSpinner.js";
import MultipleSkuModal from "../atoms/MultipleSkuModal";
import {toast} from "react-toastify";
import { buildNewQuoteLineItemFromApiData } from "../../helper/buildNewQuoteLineItemFromApiData";
import QuoteLineItem from "@kjdelectronics/ps-quotetool-domain/obj/quote/QuoteLineItem";

class ProductForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
            discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
            shippingPrice: this.props.quote.shipping.shippingPrice !== null && this.props.quote.shipping.shippingPrice !== undefined
                ? this.props.quote.shipping.shippingPrice.toString()
                : '',
            isModalOpen: false,
            showTooltip: false,
            rates: props.currencyRates ? props.currencyRates : FALLBACK_CURRENCY_RATES,
            isMultipleSkuModalOpen: false,
            isLoading: false,
            loadingProgress: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quote !== this.props.quote) {
            this.setState({
                discountValue: this.props.quote.totalsDiscount ? this.props.quote.totalsDiscount : '',
                discountPercentage: this.props.quote.discountPercent ? this.props.quote.discountPercent : '',
                shippingPrice: this.props.quote.shipping.shippingPrice !== null && this.props.quote.shipping.shippingPrice !== undefined
                    ? this.props.quote.shipping.shippingPrice.toString()
                    : '',
            });
        }
    }

    toggleModal = () => {
        if (!this.props.lineItems || this.props.lineItems.length === 0) {
            console.warn('No line items available to reorder.');
            return;
        }

        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }));
    };

    toggleMultipleSkuModal = () => {
        this.setState(prevState => ({
            isMultipleSkuModalOpen: !prevState.isMultipleSkuModalOpen
        }));
    };

    updateValue = (value, keyArray, decimalPlaces = 2) => {
        const parsedValue = parseFloat(value);
        const validValue = isNaN(parsedValue) ? 0 : parsedValue.toFixed(decimalPlaces);

        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: keyArray,
            newValue: validValue,
        });
    };

    handleReorderLineItems = (newOrder) => {
        const reorderedItems = newOrder.map((item, index) => ({
            ...item,
            order: index,
        }));

        this.props.patchQuote({
            quote: this.props.quote,
            keyArray: ['lineItems'],
            newValue: reorderedItems,
        });
    };

    async addMultipleSkusToQuote(skus) {
        try {
            this.setState({ isLoading: true, loadingProgress: 0 });
            const lineItems = [];
            const totalSkus = skus.length;
            let processedSkus = 0;

            const skuPromises = skus.map(async (sku) => {
                let product = null;
                try {
                    product = await this.props.quoteToolClient.getSaturnProductBySkuForStoreId({
                        sku,
                        storeId: this.props.storeId
                    });
                } catch (error) {
                    console.error(`Error fetching product for SKU ${sku}:`, error);
                }

                let newLineItemObject;
                if (product) {
                    newLineItemObject = await buildNewQuoteLineItemFromApiData({
                        sku,
                        product,
                        quote: this.props.quote,
                        quoteToolClient: this.props.quoteToolClient,
                        isFromMultipleSkuModal: true,
                    });
                } else {
                    newLineItemObject = new QuoteLineItem({
                        productId: null,
                        sku: sku,
                        title: "",
                        quantity: 1,
                        isFromMultipleSkuModal: true,
                        isCustomItem: true,
                    }, this.props.quote);
                }

                lineItems.push(newLineItemObject);

                processedSkus += 1;
                const progress = Math.round((processedSkus / totalSkus) * 100);
                this.setState({ loadingProgress: progress });
            });

            await Promise.all(skuPromises);

            if (lineItems.length > 0) {
                await this.props.patchQuoteAction({
                    action: "addMultipleItems",
                    data: lineItems,
                    quote: this.props.quote,
                });
            }

            toast.success("All SKUs added successfully!");
        } finally {
            this.setState({ isLoading: false, loadingProgress: 0 });
        }
    }


    toggleTooltip = () => {
        this.setState(prevState => ({ showTooltip: !prevState.showTooltip }));
    };

    render() {
        const { lineItems, quoteToolClient, quote } = this.props;
        const { discountValue, discountPercentage, isModalOpen, rates, isMultipleSkuModalOpen } = this.state;

        const profitClass = quote.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';
        const isUSStore = quote.storeId === 1000104959;

        const sortedLineItems = (lineItems || []).slice().sort((a, b) => a.order - b.order);


        return (
            <div className="product-form-container">
                <LoadingSpinner
                    isLoading={this.state.isLoading}
                    message="Adding SKUs..."
                    fullPage={true}
                    progress={this.state.loadingProgress}
                />
                <div className="small-form-container ms-5 me-5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ "margin-right": "10px" }}>Items</h3>
                        <Button size="sm"
                                color="primary"
                                onClick={this.toggleModal}>Reorder
                            Line Items</Button>
                    </div>
                    <form>
                        <table className="table form-table">
                            <thead>
                            <tr>
                                <th style={{width: '3%'}}>#</th>
                                <th style={{width: '5%'}}>SKU</th>
                                <th style={{width: '25%'}}>Title</th>
                                <th style={{width: '6%'}}>Quantity</th>
                                <th style={{width: '7%'}}>Inventory (BO?)</th>
                                <th style={{width: '5%'}}>Unit Cost</th>
                                <th style={{width: '5%'}}>Retail Price</th>
                                <th style={{width: '10%'}}>Discount</th>
                                <th style={{width: '11%'}}>Price ($)</th>
                                <th style={{width: '10%'}}>Profit</th>
                                <th>Extended (Subtotal)</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className="quote-line-item-table-compact">
                            {sortedLineItems.map((lineItem, index) => (
                                <QuoteLineItemRow
                                    key={lineItem.tempId}
                                    lineItem={lineItem}
                                    index={index}
                                    lineNumber={index + 1}
                                    quoteToolClient={quoteToolClient}
                                    patchQuote={this.props.patchQuote}
                                    patchQuoteAction={this.props.patchQuoteAction}
                                    patchLineItem={this.props.patchLineItem}
                                    setLineItem={this.props.setLineItem}
                                    quote={quote}
                                    isUSStore={isUSStore}
                                    storeId={this.props.storeId}
                                    updateValue={this.updateValue}
                                />
                            ))}
                            { quote ? <QuoteTotals  patchQuote={ this.props.patchQuote} quote={this.props.quote} rates={rates}/> : <LoadingSpinner/>}
                            </tbody>
                        </table>
                        <div className="text-start mt-3">
                            <Button
                                className="add-line-item-button"
                                onClick={() => {
                                    this.props.patchQuoteAction({action: "addItem", data: {}, quote: this.props.quote});
                                }}
                            >
                                Add Line Item
                            </Button>
                            <Button
                                className="add-multiple-sku-button"
                                onClick={this.toggleMultipleSkuModal}
                            >
                                Add Multiple SKUs
                            </Button>
                        </div>
                    </form>
                </div>

                <ReorderModal
                    isOpen={isModalOpen}
                    toggle={this.toggleModal}
                    lineItems={lineItems}
                    saveOrder={this.handleReorderLineItems}
                />

                <MultipleSkuModal
                    isOpen={isMultipleSkuModalOpen}
                    toggle={this.toggleMultipleSkuModal}
                    handleSkuAdd={this.addMultipleSkusToQuote.bind(this)}
                    quoteToolClient={quoteToolClient}
                    storeId={this.props.storeId}
                />
            </div>
        );
    }
}

ProductForm.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    storeId: PropTypes.number.isRequired,
    lineItems: PropTypes.array.isRequired,
    patchQuote: PropTypes.func.isRequired,
    patchQuoteAction: PropTypes.func.isRequired,
    patchLineItem: PropTypes.func.isRequired,
    setLineItem: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired,
    isUSStore: PropTypes.bool.isRequired
};

export default ProductForm;