import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {QuoteOrganism} from "../organism/QuoteOrganism";
import {Progress} from "reactstrap";

class LoadingSpinner extends React.Component {
    render() {
        if (this.props.isLoading === false)
            return null;
        return (
            <div className={this.props.fullPage ? "loading-overlay" : ""}>
                <div className="loading-content">
                    <div className="spinner"></div>
                    <div className="spinner-message">
                        <h2 className="loading-text">{this.props.message}</h2>
                        {this.props.progress !== undefined && (
                            <div style={{ marginTop: '10px' }}>
                                <Progress value={this.props.progress} max={100} color="success">
                                    {this.props.progress}%
                                </Progress>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    fullPage: PropTypes.bool,
    progress: PropTypes.number,
};

export default LoadingSpinner;