import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink, Table, FormGroup, Label, Input, Button } from 'reactstrap';
import {getCurrencyFormatter} from "../../helper/formatters";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import FullScreenLoadingSpinner from "../atoms/FullScreenLoadingSpinner.js";

function QuoteTable({ quoteToolClient, filter }) {
    const [quotes, setQuotes] = useState([]);
    const [error, setError] = useState(null);
    const [sortKey, setSortKey] = useState('ref');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterStoreUS, setFilterStoreUS] = useState(false);
    const [filterStoreCA, setFilterStoreCA] = useState(false);
    const [filterEmail, setFilterEmail] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [quotesPerPage, setQuotesPerPage] = useState(10);  // Default quotes per page
    const [isCloning, setIsCloning] = useState(false);
    const navigate = useNavigate();

    const storeNamesMap = {
        1000104959: 'US Store',
        1000099708: 'Canada Store',
        // TODO: Add or change mapping process
    };

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                if (quoteToolClient) {
                    const { quotes, totalCount } = await quoteToolClient.getQuotes({
                        page,
                        limit: quotesPerPage,
                        search: filterEmail,
                        filter: filter
                    });

                    console.log(quotes);
                    setQuotes(quotes);
                    setTotalPages(Math.ceil(totalCount / quotesPerPage));
                } else {
                    setError('QuoteToolClient is not initialized.');
                }
            } catch (err) {
                console.error('Error fetching quotes:', err);
                setError('Error fetching quotes. Please try again later.');
            }
        };

        fetchQuotes();
    }, [quoteToolClient, page, quotesPerPage, sortKey, sortOrder, filterStoreUS, filterStoreCA, filterEmail]);

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
    };

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handleStoreUSChange = (e) => {
        setFilterStoreUS(e.target.checked);
    };

    const handleStoreCAChange = (e) => {
        setFilterStoreCA(e.target.checked);
    };

    const handleEmailFilterChange = (e) => {
        setFilterEmail(e.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage !== page) {
            setPage(newPage);
        }
    };

    const handleQuotesPerPageChange = (event) => {
        const newQuotesPerPage = parseInt(event.target.value, 10);
        setQuotesPerPage(newQuotesPerPage);
        setPage(1);
    };

    const generatePageNumbers = () => {
        const pages = [];
        const startPage = Math.max(1, page - 2);
        const endPage = Math.min(totalPages, page + 2);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    const saveAndOpenPdf = async (quote) => {
        if (quote && quote.ref) {
            try {
                const pdfUrl = `/v1/quotes/${quote.ref}.pdf`;
                window.open(pdfUrl);
            } catch (error) {
                console.error(error);
                toast.error(`Failed to render PDF for quote ${quote.ref}`);
            }
        }
    };

    const cloneAndOpen = async (quote) => {
        if (quote && quote.ref) {
            try {
                setIsCloning(true);
                const newQuote = await quoteToolClient.cloneQuoteByRef(quote.ref);
                navigate(`/stores/${newQuote.storeId}/quotes/${newQuote.ref}`);
            } catch (error) {
                console.error(error);
                toast.error(`Failed to render PDF for quote ${quote.ref}`);
            }
        }
    };

    const filteredQuotes = quotes?.filter((quote) => {
            if (filterStoreUS && quote.storeId !== 1000104959) return false;
            if (filterStoreCA && quote.storeId !== 1000099708) return false;
            if (filterEmail && !quote.account.contacts[0]?.email.includes(filterEmail)) return false;
            return true;
        })
        .sort((a, b) => {
            let compare = 0;
            if (sortKey === 'ref') compare = a.ref - b.ref;
            if (sortKey === 'createdDate') compare = new Date(a.audit.createdDate) - new Date(b.audit.createdDate);
            return sortOrder === 'asc' ? compare : -compare;
        });

    return (
        <div>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    {isCloning ? <FullScreenLoadingSpinner/> : ""}
                    <div className="filters">
                        <div className="filter-item">
                            <label>
                                Sort by:
                                <select value={sortKey} onChange={handleSortChange}>
                                    <option value="ref">Ref#</option>
                                    <option value="createdDate">Created Date</option>
                                </select>
                            </label>
                            <label>
                                Order:
                                <select value={sortOrder} onChange={handleSortOrderChange}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </label>
                        </div>
                        <div className="filter-item">
                            <label>Filter by Store:</label>
                            <div>
                                <label>
                                    <Input
                                        type="checkbox"
                                        checked={filterStoreUS}
                                        onChange={handleStoreUSChange}
                                    />
                                    US Store
                                </label>
                            </div>
                            <div>
                                <label>
                                    <Input
                                        type="checkbox"
                                        checked={filterStoreCA}
                                        onChange={handleStoreCAChange}
                                    />
                                    Canada Store
                                </label>
                            </div>
                        </div>
                        <div className="filter-item">
                            <label>
                                Filter by Email:
                                <Input
                                    type="text"
                                    value={filterEmail}
                                    onChange={handleEmailFilterChange}
                                    placeholder="Enter Customer Email"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="quote-table-container">
                        <Table hover striped className="table-responsive">
                            <thead>
                            <tr>
                                <th>Ref#</th>
                                <th>Account</th>
                                <th style={{ "width": '7%' }}>Store</th>
                                <th>Total</th>
                                <th>Profit %</th>
                                <th>Notes</th>
                                <th>Created Date</th>
                                <th style={{ "width": '10%' }}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredQuotes?.length > 0 ? (
                                filteredQuotes.map(quote => (
                                    <tr key={quote.ref}>
                                        <td>
                                            <Link to={`/stores/${quote.storeId}/quotes/${quote.ref}`}>
                                                {quote.ref}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link className="" target="_blank" to={`/accounts/${quote.account.id}`}>
                                                <Button color="link">{quote.account.toString()}</Button>
                                            </Link>
                                        </td>
                                        <td>{storeNamesMap[quote.storeId]}</td>
                                        <td>
                                            {getCurrencyFormatter(quote.currencyCode)(quote.totals.grand)} {quote.currencyCode}
                                        </td>
                                        <td className={quote.totals.profitPercentage >= 0 ? 'text-success' : 'text-danger'}>
                                            {quote.totals.profitPercentage
                                                ? `${(quote.totals.profitPercentage * 100).toFixed(2)}%`
                                                : 'N/A'}
                                        </td>
                                        <td>{quote.detail.notes}</td>
                                        <td>{new Date(quote.audit.createdDate).toLocaleDateString()}</td>
                                        <td>
                                            <div className="button-container">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => saveAndOpenPdf(quote)}
                                                >
                                                    PDF
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline={true}
                                                    size="sm"
                                                    onClick={() => cloneAndOpen(quote)}
                                                    disabled={isCloning}
                                                >
                                                    Clone
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                <td colSpan="7">No quotes found.</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>

                    <Pagination aria-label="Quotes navigation">
                        <PaginationItem disabled={page <= 1}>
                            <PaginationLink
                                first
                                onClick={() => handlePageChange(1)}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={page <= 1}>
                            <PaginationLink
                                previous
                                onClick={() => handlePageChange(page - 1)}
                            />
                        </PaginationItem>
                        {generatePageNumbers().map((p) => (
                            <PaginationItem active={p === page} key={p}>
                                <PaginationLink onClick={() => handlePageChange(p)}>
                                    {p}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={page >= totalPages}>
                            <PaginationLink
                                next
                                onClick={() => handlePageChange(page + 1)}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={page >= totalPages}>
                            <PaginationLink
                                last
                                onClick={() => handlePageChange(totalPages)}
                            />
                        </PaginationItem>
                    </Pagination>

                    <FormGroup>
                        <Label for="quotesPerPageSelect">Quotes per page:</Label>
                        <Input
                            type="select"
                            id="quotesPerPageSelect"
                            value={quotesPerPage}
                            onChange={handleQuotesPerPageChange}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </Input>
                    </FormGroup>

                </div>
            )}
        </div>
    );
}

QuoteTable.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    filter: PropTypes.object,
};

export default QuoteTable;
