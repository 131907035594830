import React from 'react';
import { useError } from '../../context/ErrorContext';

const ErrorNotification = () => {
    const { error, clearError } = useError();

    if (!error) {
        return null;
    }

    return (
        <div className="error-notification">
            <p>Something went wrong: {error.message}</p>
            <button onClick={clearError}>Dismiss</button>
        </div>
    );
};

export default ErrorNotification;
