import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {InputGroup, InputGroupText, Input, Tooltip, Button} from 'reactstrap';
import NumericInput from "../atoms/NumericInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons";

const QuoteTotals = ({quote, rates, patchQuote}) => {
    const [discountPercent, setDiscountPercent] = useState(quote.discountPercent);
    const [totalsDiscount, setTotalsDiscount] = useState(quote.totalsDiscount);
    const [shippingPrice, setShippingPrice] = useState(quote.shipping.shippingPrice);
    const [showToolTip, setShowToolTip] = useState(false);

    const currencyFormatter = quote.getCurrencyFormatter().formatter;
    const currencyCode = quote.currencyCode;

    const profitClass = quote.profitTotal >= 0 ? 'profit-positive' : 'profit-negative';

    const updateValue = (value, keyArray, decimalPlaces = 2) => {
        const validValue = isNaN(value) ? 0 : value.round(decimalPlaces);

        patchQuote({
            quote: quote,
            keyArray: keyArray,
            newValue: validValue,
        });
    };

    const handleDiscountValueBlur = () => {
        const value = parseFloat(totalsDiscount);
        updateValue(value, ['totalsDiscount'], 2);
    };

    const handleDiscountPercentageBlur = () => {
        const percentageValue = parseFloat(discountPercent);
        updateValue(percentageValue, ['discountPercent'], 4);
    };

    const handleShippingTbd = () => {
        patchQuote({
            quote: quote,
            keyArray: ['shipping', 'shippingPrice'],
            newValue: null,
        });
        setShippingPrice("TBD");
    }

    const handleShippingBlur = (value) => {
        if(value === "") {
            handleShippingTbd();
            return;
        }

        const newValue = parseFloat(value) || 0;
        patchQuote({
            quote: quote,
            keyArray: ['shipping', 'shippingPrice'],
            newValue: newValue,
        });
    };

    const handleShippingFocus = (event) =>{
        if(shippingPrice === "TBD") {
            setShippingPrice("");
        }
    }

    const handleShippingChange = (value) => {
        setShippingPrice(value);
    };

    const handleDiscountValueChange = (value) => {
        setTotalsDiscount(value);
    };

    const handleDiscountPercentageChange = (value) => {
        setDiscountPercent(value);
    };

    const handleCurrencyChange = (newCurrency) => {
        const {grandTotal} = quote.totals || {};

        if (grandTotal > 0) {
            if (window.confirm("Changing the currency label will not convert the total value. Are you sure?")) {
                patchQuote({
                    quote: quote,
                    keyArray: ['currencyCode'],
                    newValue: newCurrency,
                });
            } else {
                // For if the user hits cancel, don't do anything
                return;
            }
        } else {
            patchQuote({
                quote: quote,
                keyArray: ['currencyCode'],
                newValue: newCurrency,
            });
        }
    }

    return (
        <>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end">Subtotal:</td>
                <td className="text-end" colSpan="2">
                    {currencyFormatter(quote.lineItemSubtotal)} {currencyCode}
                    <div className="d-flex justify-content-end ">
                        <Input
                            type="select"
                            value={quote.currencyCode}
                            style={{maxWidth: "12ch"}}
                            size="sm"
                            onChange={(e) => handleCurrencyChange(e.target.value)}
                        >
                            <option value="USD">USD</option>
                            <option value="CAD">CAD</option>
                        </Input>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end">Discount:</td>
                <td className="text-end" colSpan="2">
                    <div className="d-flex justify-content-end ">
                        <InputGroup style={{maxWidth: "16ch"}}>
                            <InputGroupText className="small-padding-input-group">$</InputGroupText>
                            <NumericInput
                                value={totalsDiscount !== null ? totalsDiscount : ''}
                                onChange={handleDiscountValueChange}
                                onBlur={handleDiscountValueBlur}
                                className="text-end me-4"
                                style={{width: "40%"}}
                                placeholder="0"
                                decimalPlaces={2}
                            />
                        </InputGroup>
                    </div>
                    <div className="d-flex justify-content-end ">
                        <InputGroup style={{maxWidth: "16ch"}}>
                            <NumericInput
                                value={discountPercent !== null ? discountPercent : ''}
                                onChange={handleDiscountPercentageChange}
                                onBlur={handleDiscountPercentageBlur}
                                className="text-end"
                                style={{width: "38%"}}
                                placeholder="0%"
                                decimalPlaces={0}
                            />
                            <InputGroupText className="small-padding-input-group me-4">%</InputGroupText>
                        </InputGroup>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end">Shipping:</td>
                <td className="text-end " colSpan="2">
                    <div className="d-flex justify-content-end ">
                        <Button
                            outline={quote.shipping.shippingPrice !== null}
                            color="secondary"
                            className="me-1" size="sm"
                            style={{fontSize: 10, padding: 4}}
                            onClick={handleShippingTbd}
                        >
                            TBD
                        </Button>
                        <InputGroup style={{maxWidth: "16ch"}}>
                            <InputGroupText className="small-padding-input-group">$</InputGroupText>
                            <NumericInput
                                value={shippingPrice === null ? "" : shippingPrice}
                                onChange={handleShippingChange}
                                onBlur={handleShippingBlur}
                                onFocus={handleShippingFocus}
                                className="text-end me-4"
                                style={{
                                    width: "35%",
                                    minWidth: '8ch',
                                }}
                                decimalPlaces={2}
                            />
                        </InputGroup>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end">Tax:</td>
                <td className="text-end" colSpan="2">
                    {quote.totals.taxes !== undefined ? currencyFormatter(quote.totals.taxes) : "N/A"} {currencyCode}
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end fw-bold">Grand Total:</td>
                <td className="text-end" colSpan="2">
                    <span className="fw-bold">{currencyFormatter(quote.grandTotal)} {currencyCode}</span>
                    <br/>
                    {(quote.totalsDiscount > 0 || quote.discountPercent > 0) && (
                        <span className="text-muted small">
                                            Discount Applied: {currencyFormatter(quote.totalsDiscount + (quote.lineItemSubtotal * (quote.discountPercent / 100)))}
                                        </span>
                    )}
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end">Total Profit:</td>
                <td className={`text-end ${profitClass}`} colSpan="2">
                    {currencyFormatter(quote.profitTotal)} {currencyCode}
                </td>
            </tr>
            <tr>
                <td colSpan="9"></td>
                <td className="text-end" colSpan="4">
                    {currencyCode !== "USD" && (
                        <>
                            <FontAwesomeIcon
                                icon={faExchangeAlt}
                                id="CurrencyRatesInfo"
                                style={{fontSize: '1.5rem', color: '#DAA520', cursor: 'pointer'}}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={showToolTip}
                                target="CurrencyRatesInfo"
                                toggle={() => setShowToolTip(!showToolTip)}
                            >
                                Base: {rates.base} | CAD: {rates.adjustedRates.cad.toFixed(4)} |
                                Base: {rates.base} | CAD: {rates.adjustedRates.cad.toFixed(4)} |
                                USD: {rates.adjustedRates.usd.toFixed(4)} |
                                EUR: {rates.adjustedRates.eur.toFixed(4)} |
                                GBP: {rates.adjustedRates.gbp.toFixed(4)}
                                <hr/>
                                Additional Applied Adjustment: {rates.adjustmentPercent * 100}%
                                <br/>
                                Exchange Rate Date: {rates.date ? rates.date : "Using fallback rate"}
                            </Tooltip>
                        </>
                    )}
                </td>

            </tr>
        </>
    );
};

QuoteTotals.propTypes = {
    quote: PropTypes.object.isRequired,
    patchQuote: PropTypes.func.isRequired,
    rates: PropTypes.object.isRequired,
};

export default QuoteTotals;